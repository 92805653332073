import {ParentHandleMessageType, ParentPostMessageType} from "./Enums/common.enum.js";
import {
  getLocalStorageValue,
  mobileCheck,
  parentPostMessage,
  xmlHttpRequest
} from "./helper/common-function.helper.js";
import {getValue} from "./helper/common-var.helper.js";
const codeBaseUrl = "https://saas.staging.antonic.ai/app/webchat/app/";
const loadAntonic = async () => {
  function processBotSettings(botSettings) {
    if (botSettings.chatBotDesign) {
      const user = getLocalStorageValue(`${botSettings.chatBotDesign._id}-antonic-staging-user`);
      if (botSettings.isGDPRUserConsentScreenEnabled && user) {
        const userId = getValue("myUserId");
        xmlHttpRequest("GET", `${baseUrl}directline/privacy/${userId}/${window.customToken}`, ({privacyPolicyAccepted}) => {
          botSettings.privacyPolicyAccepted = privacyPolicyAccepted;
          processData(botSettings);
        }).send();
      } else {
        botSettings.privacyPolicyAccepted = false;
        processData(botSettings);
      }
    }
  }
  function getBrowserName(userAgent) {
    if (userAgent.indexOf("SamsungBrowser") > -1) {
      return "Samsung Internet";
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
      return "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
      return "Microsoft Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
      return "Microsoft Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
      return "Google Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      return "Apple Safari";
    } else if (userAgent.indexOf("Firefox") > -1) {
      return "Mozilla Firefox";
    } else {
      return "unknown";
    }
  }
  function getConsentScreenData(consentData) {
    const consentScreenData = {
      privacyPolicyAccepted: consentData.privacyPolicyAccepted,
      isGDPRUserConsentScreenEnabled: consentData.isGDPRUserConsentScreenEnabled
    };
    if (consentData.isGDPRUserConsentScreenEnabled) {
      return {
        ...consentScreenData,
        GDPRConsentScreenDesign: consentData.GDPRConsentScreenDesign,
        consentScreenMessages: consentData.consentScreenMessages
      };
    }
    return consentScreenData;
  }
  async function processData(botSettings) {
    let {chatBotDesign, chatBotWidgetWindowDesign, whiteListUrls} = botSettings;
    isEllipse = chatBotDesign.startIconOptions.shape === "ellipse";
    await new Promise((resolve) => {
      let getBase64Request = new XMLHttpRequest();
      getBase64Request.onload = function() {
        let reader = new FileReader();
        reader.onloadend = () => chatBotDesign.botImage = reader.result;
        reader.readAsDataURL(getBase64Request.response);
      };
      getBase64Request.open("GET", chatBotDesign.botImage);
      getBase64Request.responseType = "blob";
      getBase64Request.send();
      resolve();
    });
    tmpIframe = document.createElement("iframe");
    tmpIframe.id = "myFrame";
    tmpIframe.allowFullscreen = true;
    tmpIframe.name = "myFrame";
    tmpIframe.src = `${codeBaseUrl}index.html`;
    tmpIframe.style.zIndex = "9999999999";
    tmpIframe.style.border = "transparent";
    tmpIframe.style.position = "fixed";
    tmpIframe.style.bottom = "0px";
    tmpIframe.style.display = "block";
    tmpIframe.style.transition = "0.3s right ease, 0.3s bottom ease";
    tmpIframe.style.right = "-100%";
    tmpIframe.style.bottom = "-100%";
    if (window.isPreview) {
      chatBotDesign.size = chatBotDesign.size > 1 ? 1 : chatBotDesign.size;
      document.getElementById("bot_preview_container")?.appendChild(tmpIframe);
    } else {
      document.getElementsByTagName("body")[0].appendChild(tmpIframe);
    }
    let iframeElementOfDom = document.getElementById("myFrame");
    let position = chatBotDesign.position ? chatBotDesign.position : "right";
    iframeElementOfDom.style.transformOrigin = `bottom ${chatBotDesign.botView !== "fullPage" && (device.iPad || device.iPhone || device.Android) ? position : "center"}`;
    iframeElementOfDom.style[position] = "0";
    let chatBotSize = chatBotDesign.size ? chatBotDesign.size : 1;
    if (device.iPad || device.iPhone || device.Android) {
      iframeElementOfDom.style.width = "100%";
      iframeElementOfDom.style.height = "100%";
    } else {
      iframeElementOfDom.style.width = 70 * chatBotSize + "px";
      iframeElementOfDom.style.height = 80 * chatBotSize + "px";
    }
    tmpIframe.onload = function() {
      iframeWin = iframeElementOfDom.contentWindow;
      const consentScreenData = getConsentScreenData({
        isGDPRUserConsentScreenEnabled: botSettings.isGDPRUserConsentScreenEnabled,
        privacyPolicyAccepted: botSettings.privacyPolicyAccepted,
        GDPRConsentScreenDesign: botSettings.GDPRConsentScreenDesign,
        consentScreenMessages: botSettings.consentScreenMessages
      });
      parentPostMessage(ParentPostMessageType.BOTLOAD, {chatBotDesign});
      iframeWin?.postMessage(JSON.stringify({
        windowData: chatBotWidgetWindowDesign,
        chatBotDesign,
        consentScreenData,
        whiteListUrls,
        isMobile: mobileCheck(),
        customToken: window.customToken,
        baseUrl,
        browser: getBrowserName(navigator.userAgent),
        isPreview: window.isPreview,
        type: "message",
        user: getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-user`),
        fcmToken: getLocalStorageValue(`${chatBotDesign._id}-fcm-token`),
        origin: window.location.href,
        conversationId: getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-conversationId`),
        messages: getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-messages`),
        percentage: getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-percentage`) === "undefined" ? 0 : getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-percentage`),
        currentLanguage: getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-currentLanguage`)
      }), `${codeBaseUrl}index.html`);
      if (window.addEventListener) {
        window.addEventListener("message", handleMessage);
        window.addEventListener("StartFlow", ({detail}) => {
          iframeWin?.postMessage(JSON.stringify({
            chatBotDesign,
            whiteListUrls,
            isMobile: mobileCheck(),
            customToken: window.customToken,
            baseUrl,
            browser: getBrowserName(navigator.userAgent),
            isPreview: window.isPreview,
            user: getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-user`),
            fcmToken: getLocalStorageValue(`${chatBotDesign._id}-fcm-token`),
            origin: window.location.href,
            conversationId: getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-conversationId`),
            messages: getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-messages`),
            percentage: getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-percentage`) === "undefined" ? 0 : getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-percentage`),
            currentLanguage: getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-currentLanguage`),
            type: "StartFlow",
            triggerId: detail && detail.flowId ? detail.flowId : detail,
            eventType: detail && detail.eventType ? detail.eventType : "flow",
            eventTriggeredCount: detail && detail.eventTriggeredCount ? detail.eventTriggeredCount : 0
          }), codeBaseUrl + "index.html");
        }, false);
      } else {
        window.attachEvent("onmessage", handleMessage);
      }
      if (getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-user`)?.myUserId) {
        xmlHttpRequest("POST", `${baseUrl}directline/conversations/reconnect/${getLocalStorageValue(`${chatBotDesign._id}-antonic-staging-user`)?.myUserId}`, () => {
        }, []).send();
      }
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get("flowId");
      const refFlowId = urlParams.get("ref");
      if (myParam) {
        window.dispatchEvent(new CustomEvent("StartFlow", {
          detail: {flowId: myParam, eventType: "proactive-message"}
        }));
      }
      if (refFlowId) {
        let eventTriggeredCount = localStorage.getItem("eventTriggeredCount");
        eventTriggeredCount = eventTriggeredCount ? parseInt(eventTriggeredCount) + 1 : 0;
        localStorage.setItem("eventTriggeredCount", eventTriggeredCount.toString());
        window.dispatchEvent(new CustomEvent("StartFlow", {
          detail: {flowId: refFlowId, eventType: "ref-url", eventTriggeredCount}
        }));
      }
      function handleMessage({data: eventData}) {
        let botView = eventData?.botView ? eventData.botView : chatBotDesign.botView;
        switch (eventData.type) {
          case ParentHandleMessageType.BOT_OPEN:
          case ParentHandleMessageType.BOT_CLOSE:
            if (mobileCheck()) {
              if (device.iPad || device.iPhone || device.Android) {
                tmpIframe.style.height = "100%";
                tmpIframe.style.width = "100%";
                if (eventData.type === "bot_close") {
                  tmpIframe.style.bottom = "calc(80px - 100%)";
                  tmpIframe.style.right = `calc(${isEllipse ? 120 : 80}px - 100%)`;
                }
              } else {
                tmpIframe.style.height = "calc(100% - 10px)";
                tmpIframe.style.width = "calc(100vw - 10px)";
              }
            } else {
              if (botView === "mini") {
                tmpIframe.style.width = eventData.width * chatBotSize + 30 + "px";
                tmpIframe.style.height = eventData.height * chatBotSize + 30 + "px";
                if (eventData.type === "bot_close") {
                  tmpIframe.style.bottom = 80 - (eventData.height * chatBotSize + 30) + "px";
                  tmpIframe.style.right = (isEllipse ? 120 : 80) - (eventData.width * chatBotSize + 30) + "px";
                }
              } else if (botView === "full") {
                tmpIframe.style.width = eventData.width * 1 + "px";
                tmpIframe.style.height = 100 + "vh";
                if (eventData.type === "bot_close") {
                  tmpIframe.style.bottom = `calc(80px - 100%)`;
                  tmpIframe.style.right = (isEllipse ? 120 : 80) - eventData.width * 1 + "px";
                }
              } else {
                tmpIframe.style.width = "100%";
                tmpIframe.style.height = "100%";
              }
            }
            if (eventData.type === "bot_open") {
              tmpIframe.style.bottom = "0";
              tmpIframe.style.right = "0";
            }
            break;
          case ParentHandleMessageType.ADD_TO_STORAGE:
            if (eventData.key && eventData.value) {
              localStorage.setItem(eventData.key, eventData.value);
            }
            break;
          case ParentHandleMessageType.REMOVE_STORAGE:
            localStorage.removeItem(`${chatBotDesign._id}-antonic-staging-user`);
            localStorage.removeItem(`${chatBotDesign._id}-antonic-staging-conversationId`);
            localStorage.removeItem(`${chatBotDesign._id}-antonic-staging-messages`);
            break;
          case "percentage":
            localStorage.setItem(chatBotDesign._id + "-antonic-staging-percentage", eventData.percentage);
            break;
          case ParentHandleMessageType.RESTART_DATA_PREVIEW:
            localStorage.removeItem(`${chatBotDesign._id}-antonic-staging-messages`);
            break;
        }
      }
    };
    tmpIframe.onerror = function() {
      if (window.isPreview) {
        parentPostMessage(ParentPostMessageType.BOTLOADERROR);
      }
    };
    if (botSettings?.firebaseConfig) {
      const {initFirebaseApp} = await import("./helper/firebase.helper.js");
      initFirebaseApp(botSettings);
    }
  }
  const baseUrl = "https://saas.staging.antonic.ai/app/";
  let isEllipse = false;
  const ua = navigator.userAgent;
  const device = {
    iPad: /iPad/.test(ua),
    iPhone: /iPhone/.test(ua),
    Android: /Android/.test(ua)
  };
  const {getBotSettings} = await import("./helper/api.helper.js");
  getBotSettings({refUrl: window.location.href, isGrowthTool: false, userLocale: navigator.language}).then((response) => {
    if (response.data?.message && response.data.status) {
      console.error(response.data.message);
    } else {
      processBotSettings(response.data);
    }
  }).catch((err) => console.error("API ERROR:", err));
  let tmpIframe;
  let iframeWin;
  window.addEventListener("TriggerFlow", ({detail}) => {
    const customEvent = new CustomEvent("StartFlow", {detail: detail && detail.flowId ? detail.flowId : detail});
    if (tmpIframe === void 0) {
      getBotSettings({refUrl: window.location.href, isGrowthTool: true}).then((response) => {
        if (response.data?.message && response.data.status) {
          console.error(response.data.message);
        } else {
          processBotSettings(response.data);
        }
      }).catch((err) => console.error("API ERROR:", err));
      const timer = setInterval(() => {
        if (iframeWin) {
          window.dispatchEvent(customEvent);
          clearInterval(timer);
        }
      }, 800);
    } else {
      window.dispatchEvent(customEvent);
    }
  }, false);
};
window.onload = loadAntonic();
function toggleBotState(openBot = false) {
  document.getElementById("myFrame").contentWindow?.postMessage(JSON.stringify({
    type: openBot ? "openBot" : "closeBot"
  }), `${codeBaseUrl}/src/index.html`);
}
window.antonicBotSDK = {
  toggleBotState
};
