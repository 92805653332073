export var ParentPostMessageType;
(function(ParentPostMessageType2) {
  ParentPostMessageType2["BOTLOAD"] = "botLoad";
  ParentPostMessageType2["BOTLOADERROR"] = "botLoadError";
  ParentPostMessageType2["NEWUSER"] = "newUser";
})(ParentPostMessageType || (ParentPostMessageType = {}));
export var ParentHandleMessageType;
(function(ParentHandleMessageType2) {
  ParentHandleMessageType2["BOT_OPEN"] = "bot_open";
  ParentHandleMessageType2["BOT_CLOSE"] = "bot_close";
  ParentHandleMessageType2["ADD_TO_STORAGE"] = "addToLocalStorage";
  ParentHandleMessageType2["REMOVE_STORAGE"] = "removeStorage";
  ParentHandleMessageType2["RESTART_DATA_PREVIEW"] = "restartPreviewData";
})(ParentHandleMessageType || (ParentHandleMessageType = {}));
export var PreviewChannels;
(function(PreviewChannels2) {
  PreviewChannels2["WEBCHAT"] = "webchat";
  PreviewChannels2["WHATSAPP"] = "whatsapp";
  PreviewChannels2["FACEBOOK"] = "facebook";
  PreviewChannels2["TWILIO_SMS"] = "twilioSms";
  PreviewChannels2["TWILIO"] = "twilio";
  PreviewChannels2["DIALOG360"] = "dialog360";
  PreviewChannels2["UNIVERSAL"] = "universal";
  PreviewChannels2["INSTAGRAM"] = "instagram";
  PreviewChannels2["TELEGRAM"] = "telegram";
})(PreviewChannels || (PreviewChannels = {}));
export var IframeHandleMessageType;
(function(IframeHandleMessageType2) {
  IframeHandleMessageType2["OPEN_BOT"] = "openBot";
  IframeHandleMessageType2["CLOSE_BOT"] = "closeBot";
  IframeHandleMessageType2["PREVIEW_EVENTS"] = "previewEvents";
  IframeHandleMessageType2["START_FLOW"] = "StartFlow";
})(IframeHandleMessageType || (IframeHandleMessageType = {}));
export var PreviewEvents;
(function(PreviewEvents2) {
  PreviewEvents2["PREVIEW_SHOW_HIDE"] = "previewShowHide";
  PreviewEvents2["FLOW_CHANGE"] = "flowChanged";
  PreviewEvents2["CHANNEL_CHANGE"] = "channelChanged";
  PreviewEvents2["RESTART_CHAT"] = "restartChat";
})(PreviewEvents || (PreviewEvents = {}));
export var ActivitiesType;
(function(ActivitiesType2) {
  ActivitiesType2["MESSAGE"] = "message";
  ActivitiesType2["EVENT"] = "event";
  ActivitiesType2["TYPING"] = "typing";
  ActivitiesType2["MEDIA"] = "media";
  ActivitiesType2["TEXT"] = "text";
  ActivitiesType2["QUICK_REPLY"] = "quickReply";
  ActivitiesType2["QUESTION"] = "question";
})(ActivitiesType || (ActivitiesType = {}));
export var MessageType;
(function(MessageType2) {
  MessageType2["SLIDER"] = "slider";
  MessageType2["REVIEW"] = "review";
  MessageType2["CHECKLIST"] = "checklist";
  MessageType2["LIST"] = "list";
  MessageType2["BUTTON_WITH_IMAGE"] = "buttonWithImage";
  MessageType2["FORM"] = "form";
  MessageType2["MULTI_SELECT"] = "multiselect";
  MessageType2["QUESTION_TYPE"] = "questionType";
  MessageType2["SCHEDULER"] = "scheduler";
  MessageType2["BOOKING"] = "booking";
  MessageType2["DYNAMIC_QUICK_REPLY"] = "dynamicQuickReply";
  MessageType2["MCQ"] = "mcq";
  MessageType2["INTERACTIVE"] = "interactive";
  MessageType2["CONTACTS"] = "contacts";
  MessageType2["LOCATION"] = "location";
  MessageType2["CONTACTINFO"] = "contactInfo";
})(MessageType || (MessageType = {}));
export var AttachmentContentType;
(function(AttachmentContentType2) {
  AttachmentContentType2["IMAGE"] = "image/*";
  AttachmentContentType2["VIDEO"] = "video/*";
  AttachmentContentType2["AUDIO"] = "audio/*";
  AttachmentContentType2["CARD_HERO"] = "application/vnd.microsoft.card.hero";
  AttachmentContentType2["CARD_THUMBNAIL"] = "application/vnd.microsoft.card.thumbnail";
})(AttachmentContentType || (AttachmentContentType = {}));
(function(MessageType2) {
  MessageType2["BOT"] = "bot";
  MessageType2["USER"] = "user";
})(MessageType || (MessageType = {}));
export var DocTypeFileTypeEnum;
(function(DocTypeFileTypeEnum2) {
  DocTypeFileTypeEnum2["pdf/*"] = "pdf.svg";
  DocTypeFileTypeEnum2["doc/*"] = "doc.svg";
  DocTypeFileTypeEnum2["ppt/*"] = "ppt.svg";
  DocTypeFileTypeEnum2["xls/*"] = "xls.svg";
})(DocTypeFileTypeEnum || (DocTypeFileTypeEnum = {}));
