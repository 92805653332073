import {getValue, setValue, scrollID} from "./common-var.helper.js";
import {ErrorsCodes, whatsappChannels} from "../consts/common.const.js";
import {disableGoBack, disableSkip, getBotIcon, removeBot, storeMessages} from "../app/app.helper.js";
import {
  ParentPostMessageType,
  PreviewChannels
} from "../Enums/common.enum.js";
import moment from "../_snowpack/pkg/moment/moment.js";
export const loadScript = (url, callback) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = url;
  script.onload = callback;
  document.getElementsByTagName("head")[0].appendChild(script);
};
export const getLocalStorageValue = (key, isJson = false) => {
  if (localStorage.getItem(key)) {
    if (isJson) {
      return JSON.parse(localStorage.getItem(key));
    }
    return localStorage.getItem(key);
  }
  return null;
};
export const xmlHttpRequest = (method, url, successCallback, headerList = [{header: "Content-Type", value: "application/json; charset=utf-8"}]) => {
  const request = new XMLHttpRequest();
  request.open(method, url);
  request.onreadystatechange = () => {
    if (request.readyState === 4) {
      successCallback(JSON.parse(request.responseText));
    }
  };
  headerList.forEach(({header, value}) => {
    request.setRequestHeader(header, value);
  });
  return request;
};
export const mobileCheck = () => {
  const browser = navigator.userAgent || navigator.vendor;
  return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(browser) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(browser.substr(0, 4));
};
export const parentPostMessage = (type, data) => {
  parent.postMessage({
    type,
    [type]: data
  }, window.location.href);
};
export const getDeviceObject = (userAgent) => {
  return {
    iPad: /iPad/.test(userAgent),
    iPhone: /iPhone/.test(userAgent),
    Android: /Android/.test(userAgent)
  };
};
export const randomNumberGenerator = () => {
  return Math.floor(Math.random() * 1e5);
};
export const initializeFormDate = (id, formId, language) => {
  $("#" + id).datepicker({
    language,
    inline: false,
    autoClose: true,
    timepicker: false,
    dateFormat: "D, d MM yyyy",
    position: "top left",
    dateTimeSeparator: " ",
    clear: "Clear",
    onSelect: (dateText, inst) => {
      getValue("formMap")[formId].elements.forEach((items) => {
        if (items.id === id) {
          items.value = inst;
        }
      });
    }
  });
};
export const initializeFormDateTime = (id, formId, language) => {
  $("#" + id).datepicker({
    language,
    inline: false,
    autoClose: true,
    timepicker: true,
    onlyTimepicker: false,
    dateFormat: "D, d MM yyyy",
    timeFormat: "hh:ii",
    position: "top left",
    dateTimeSeparator: " ",
    clear: "Clear",
    onSelect: (dateText, inst) => {
      getValue("formMap")[formId].elements.forEach((items) => {
        if (items.id === id) {
          items.value = inst;
        }
      });
    }
  });
};
export const addBotResponse = (reply) => {
  let fromDelimiter = ({text, startString, endString}) => {
    let finalArray = [];
    if (!text)
      return finalArray;
    let startArray = text.split(startString);
    for (let i = 1; i < startArray.length; i++) {
      let tmp = startArray[i].split(endString, 1)[0];
      if (tmp)
        finalArray.push(tmp);
      else
        finalArray.push("");
    }
    return finalArray;
  };
  let replaceDynamicFields = (dynamicText) => {
    let extractedArray = fromDelimiter({
      text: dynamicText,
      startString: "##",
      endString: "##"
    });
    let newDynamicText = dynamicText;
    extractedArray.forEach(function(d) {
      newDynamicText = newDynamicText.replace("##" + d + "##", '<span style="font-size: var(--font-18)">' + d + "</span>");
    });
    return newDynamicText;
  };
  let temp = `
        <div class="bot_message">
            ${getBotIcon()}
            <div class="bot_message_text text_msg position-relative">
                <span style="${getValue("currentStepType") === "mcq" && whatsappChannels.includes(getValue("selectedChannel")) ? "white-space: pre-line" : ""}">
                    ${replaceDynamicFields(reply)}
                </span>
            </div>
        </div>
    `;
  insertAdjacentHTMLOfChatArea(temp);
  if (whatsappChannels.includes(getValue("selectedChannel"))) {
    $(".bot_message_text").addClass("whatsApp-bot-message");
  }
  if (PreviewChannels.TELEGRAM === getValue("selectedChannel")) {
    $(".bot_message_text").last().addClass("telegram-bot-message");
    $(".bot_message_text").last().append(`<div class="telegram-bot-chat-bubble"> <img src="assets/images/telegram-bot.svg" </div>`);
  }
  removeBot();
  scroll();
};
export const showPrivacyPolicyDialog = (dialog) => {
  $("#dialogHtml").remove();
  $(".bot_chat_area_container").append(getValue("dialogHtml"));
  if (dialog === "second") {
    $("#dialogHtml").append(getValue("dialogHtml2"));
  } else if (dialog === "first") {
    $("#dialogHtml").append(getValue("dialogHtml1"));
  }
};
export const changeInputDisable = (type, makeSendDisable = true) => {
  const userInput = $("#user_input");
  if (whatsappChannels.includes(getValue("selectedChannel")) || getValue("selectedChannel") === "facebook" || getValue("selectedChannel") === PreviewChannels.TELEGRAM) {
    type = false;
  }
  if (type) {
    userInput.prop("disabled", true);
    userInput.prop("placeholder", "");
    userInput.css({
      background: "#d6d6d6",
      cursor: "not-allowed"
    });
    if (makeSendDisable) {
      $(".send_btn").css({
        color: "#d6d6d6",
        cursor: "not-allowed"
      });
    } else {
      $(".send_btn").css({color: "white", cursor: "pointer"});
    }
    $(".bot_input").css({
      background: "#d6d6d6",
      cursor: "not-allowed"
    });
  } else {
    userInput.prop("disabled", false);
    userInput.css({background: "white", cursor: "auto"});
    $(".send_btn").css({color: "white", cursor: "pointer"});
    $(".bot_input").css({background: "white", cursor: "pointer"});
    userInput.prop("placeholder", ErrorsCodes[getValue("currentValidationLanguage")].typeMessage);
  }
};
export const disablePreviousMessageType = (type) => {
  const className = {
    review: ".review__container",
    slider: ".slider__container",
    checklist: ".checklist__container",
    scheduler: ".appointment-main , .time-parent",
    booking: ".appointment-main , .time-parent",
    form: ".form__container"
  };
  $("#scrollId").last().find(className[type]).children().find("*").css({"pointer-events": "none"});
};
export const addButtonTemplate = (data, txt) => {
  let buttons = "";
  if (data) {
    let tmpId = "";
    data.forEach((b, index) => {
      tmpId = Date.now() + "-" + index;
      getValue("buttonMap")[tmpId] = b;
      if (PreviewChannels.INSTAGRAM === getValue("selectedChannel")) {
        buttons += `<div class="textTemplateButton">
                        <button class="slider-btn instagram-bot-icon buttons" id="${tmpId}">${b.title}</button>
                    </div>`;
      } else {
        buttons += `<div class="textTemplateButton">
                        <button class="slider-btn buttons" id="${tmpId}">${b.title}</button>
                    </div>`;
      }
    });
  }
  let temp = `<div class="bot_message ">
            ${getBotIcon()}
            <div class="btn_temp">
                <div class='card' style='padding-left: 16px; padding-bottom: 10px;'>
                    <p class='btn_temp_text' style='padding-left: 0; padding-bottom: 0;'>${txt}</p>
                </div>
                ${buttons}
            </div>
        </div>`;
  insertAdjacentHTMLOfChatArea(temp);
  removeBot();
  scroll();
};
export const addConfigMessage = (configMessage) => {
  let handoffConfigMessage = `<div class="bot_message config-message">
            <div class="config_message_text">
                <span>${configMessage}</span>
            </div>
        </div>`;
  insertAdjacentHTMLOfChatArea(handoffConfigMessage);
  removeBot();
  scroll();
};
export const addDate = (i, language) => {
  removeBot();
  insertAdjacentHTMLOfChatArea(`<input type="text" class='d1' id="date${i}" hidden>
            <button class="date_button" id="date_btn${i}"></button>`);
  $("#date" + i).datepicker({
    language,
    inline: true,
    timepicker: false,
    dateFormat: "D, d MM yyyy",
    toggleSelected: true,
    onlyTimepicker: false,
    dateTimeSeparator: " ",
    onSelect: (fd, d) => {
      let b = "date_btn" + i;
      document.getElementById(b.toString()).value = fd;
      document.getElementById(b.toString()).innerHTML = fd;
    }
  });
  scroll();
  changeInputDisable(true);
  InitializeDate(i, language);
  scroll();
};
export const InitializeDate = (i, language) => {
  let date = new Date();
  let momentLocale = moment(date);
  const ToDate = momentLocale.locale(language).format("ddd, D MMMM YYYY");
  $(".value").val(ToDate);
  let b = "date_btn" + i;
  const id = document.getElementById(b);
  if (id) {
    id.value = ToDate;
    id.innerHTML = ToDate;
  }
};
export const updatePercentage = (progressPercentage) => {
  setValue("percentage", progressPercentage ? progressPercentage > 100 ? 100 : progressPercentage < 0 ? 0 : progressPercentage : 0);
  storePercentage(getValue("percentage"));
  const element = document.getElementById("progreesBarInner");
  element.style.width = getValue("percentage") + "%";
  element.innerHTML = `<span>${getValue("percentage")}%</span>`;
};
export const storePercentage = (percentage) => {
  parent.postMessage({
    type: "percentage",
    percentage
  }, getValue("currentDomain"));
};
export const addClassInput = () => {
  if ($("#user-input").prop("disabled")) {
    $(".input").addClass("disableTrue");
  } else {
    $(".input").removeClass("disableTrue");
  }
};
export const waitingBot = () => {
  if (whatsappChannels.includes(getValue("selectedChannel"))) {
    return;
  }
  removeBot();
  disableGoBack();
  disableSkip();
  let temp = `<div id="wait" class="bot_message">
                ${getBotIcon()}
                <div class ="loader">
                    <div class="bot_message_text text_msg wave position-relative">
                         <span class='dot'></span>
                         <span class='dot'></span>
                         <span class='dot' ></span>
                         <span class='dot' ></span>
                    </div>
                </div>
            </div>`;
  insertAdjacentHTMLOfChatArea(temp);
  if (whatsappChannels.includes(getValue("selectedChannel"))) {
    $(".bot_message_text").addClass("whatsApp-bot-message");
  }
  if (PreviewChannels.TELEGRAM === getValue("selectedChannel")) {
    $(".bot_message_text").last().addClass("telegram-bot-message");
    $(".bot_message_text").last().append(`<div class="telegram-bot-chat-bubble"> <img src="assets/images/telegram-bot.svg" </div>`);
  }
  scroll();
};
export const sendUniversalActivity = (activityObj) => {
  let connectionRequest = new XMLHttpRequest();
  connectionRequest.open("POST", `${getValue("baseUrl")}universal/conversations/${getValue("currentConversationID")}/activities`);
  connectionRequest.onreadystatechange = () => {
    if (connectionRequest.readyState === 4) {
      const response = JSON.parse(connectionRequest.responseText);
    }
  };
  connectionRequest.setRequestHeader("Content-Type", "application/json; charset=utf-8");
  connectionRequest.setRequestHeader("Authorization", getValue("universalAccessToken"));
  if (activityObj.type === "text") {
    let obj = {
      from: {
        id: getValue("myUserId")
      },
      type: "text",
      text: activityObj.text
    };
    connectionRequest.send(JSON.stringify(obj));
  } else if (activityObj.type === "buttonClick") {
    let obj = {
      from: {
        id: getValue("myUserId")
      },
      type: "postback",
      postback: {title: activityObj.title, payload: activityObj.text}
    };
    connectionRequest.send(JSON.stringify(obj));
  } else if (activityObj.type === "media") {
    let obj = {
      from: {id: getValue("myUserId")},
      type: "media",
      attachment: {
        contentType: activityObj.fileType,
        contentUrl: activityObj.fileUrl,
        fileName: activityObj.fileName
      }
    };
    connectionRequest.send(JSON.stringify(obj));
    $("#file-upload").hide();
    setValue("isFileUploadInProgress", false);
    changeInputDisable(false);
  } else if (activityObj.type === "date") {
    let obj = {
      from: {id: getValue("myUserId")},
      type: "text",
      text: activityObj.date.toISOString(),
      questionType: "date"
    };
    connectionRequest.send(JSON.stringify(obj));
  } else {
    connectionRequest.send(JSON.stringify(activityObj));
  }
};
export function buttonClick(obj) {
  if (getValue("buttonMap")[obj]) {
    if (getValue("GDPRObject").isGDPRUserConsentScreenEnabled && !getValue("GDPRObject").privacyPolicyAccepted) {
      showPrivacyPolicyDialog("first");
      const lastObject = getValue("lastObject");
      lastObject.type = "button";
      lastObject.value = obj;
      setValue("lastObject", lastObject);
      return;
    }
    const teleggramHTML = PreviewChannels.TELEGRAM === getValue("selectedChannel") ? '<div class="telegram-user-chat-bubble"> <img src="assets/images/telegram-user.svg"></div>' : "";
    const telegramClass = PreviewChannels.TELEGRAM === getValue("selectedChannel") ? "telegram-user-message" : "";
    let tmpObj = JSON.parse(JSON.stringify(getValue("buttonMap")[obj]));
    if (tmpObj.type === "listText") {
      let temp = `<div class="user-message--container">
                    <div class="btn-message user_message position-relative ${telegramClass}">${tmpObj.title} ${teleggramHTML}</div>
                </div>`;
      buttonClickHelper(tmpObj, temp, {
        text: tmpObj.title,
        type: "user"
      }, tmpObj.redirect, true);
    } else if (tmpObj.type === "buttonText") {
      let temp = `<div class="user-message--container">
                    <div class="btn-message user_message position-relative ${telegramClass}">${tmpObj.title} ${teleggramHTML}</div>
                </div>`;
      buttonClickHelper(tmpObj, temp, {
        text: tmpObj.title,
        type: "user"
      }, tmpObj.value, true);
    } else if (tmpObj.type === "review") {
      disablePreviousMessageType(getValue("currentStepType"));
      let temp = `<div class="user-message--container">
                     <div class="btn-message user_message position-relative" style="display: flex;align-items: center">
                        <img src="${tmpObj.image}" height='18px' width='18px' style='margin-right: 10px'/>
                        <div>${tmpObj.caption}</div>
                     </div>
                </div>`;
      buttonClickHelper(tmpObj, temp, {
        text: tmpObj.caption,
        type: "user",
        isReview: true,
        img: tmpObj.image
      }, tmpObj.caption, true, {type: "review"});
    } else if (tmpObj.type === "listImage") {
      let temp = `
                <div class="user_message__image">
                    <div class="user_message__image--container">
                        <img src="${tmpObj.image}" class="user_message__image--bot_image_sub">
                        <div class='list--horizontal__card__details'>
                            <p class='list--horizontal__card__title'>${tmpObj.title}</p>
                            <p class='list--horizontal__card__text'>${tmpObj.value}</p>
                        </div>
                    </div>
                </div>`;
      buttonClickHelper(tmpObj, temp, {
        text: tmpObj.title,
        type: "user"
      }, tmpObj.redirect, true);
    } else if (tmpObj.type === "interactive") {
      if (document.getElementById(obj)) {
        document.getElementById(obj).removeAttribute("onclick");
        $(`#${obj}`).addClass("interactive-button-disable");
      }
      let tempButton = `<div class="user-message--container">
                                    <div class="btn-message user_message position-relative"> ${tmpObj.title}</div>
                              </div>`;
      buttonClickHelper(tmpObj, tempButton, {
        text: tmpObj.title,
        type: "user"
      }, tmpObj.value, false);
    } else {
      const messages = getValue("messages");
      if (tmpObj.type === "openUrl") {
        const buttonTitle = tmpObj.title ? tmpObj.title : tmpObj.text;
        if (tmpObj.buttonPayload && tmpObj.buttonPayload.value) {
          sendMessagePostBackDirectline(tmpObj.buttonPayload.value, buttonTitle);
        }
        window.open(tmpObj.value);
      } else {
        let tmpValue = tmpObj.value ? tmpObj.value : tmpObj.text;
        let tmpTitle = tmpObj.title ? tmpObj.title : tmpObj.text;
        let temp = `<div class="user-message--container">
                        <div class="btn-message user_message position-relative ${telegramClass}">${tmpTitle} ${teleggramHTML}</div>
                    </div>`;
        insertAdjacentHTMLOfChatArea(temp);
        scrollID.scrollTop = scrollID.scrollHeight;
        let message = messages[messages.length - 1];
        if (message?.attachments && message.attachments[0] && message.attachments[0].contentType === "application/vnd.microsoft.card.hero") {
          if (message.attachments[0].content.title || message.attachments[0].content.images || message.attachments[0].content.subtitle) {
          } else {
            messages[messages.length - 1].attachments[0].content.buttons.length = 0;
          }
        }
        messages.push({
          text: tmpTitle,
          type: "user"
        });
        setValue("messages", messages);
        sendMessagePostBackDirectline(tmpValue, tmpTitle);
        changeInputDisable(false);
        $(".button").remove();
      }
    }
  }
}
function buttonClickHelper(tmpObj, temp, message, directLineText, isRemoveDisable, postback) {
  insertAdjacentHTMLOfChatArea(temp);
  scrollID.scrollTop = scrollID.scrollHeight;
  const messages = getValue("messages");
  messages.push(message);
  setValue("messages", messages);
  delete tmpObj.type;
  getValue("directLine").postActivity({
    channel: getValue("selectedChannel"),
    from: {
      id: getValue("myUserId"),
      name: getValue("myUserName")
    },
    type: "message",
    text: directLineText,
    value: {postback: postback ? postback : tmpObj}
  }).subscribe(function(id) {
  }, function(error) {
    return console.log("Error posting activity", error);
  });
  if (isRemoveDisable) {
    changeInputDisable(false);
    $(".button").remove();
  }
}
export function sendMessagePostBackDirectline(text, title) {
  if (getValue("selectedChannel") === "universal") {
    sendUniversalActivity({type: "buttonClick", text, title});
  } else {
    getValue("directLine").postActivity({
      channel: getValue("selectedChannel"),
      from: {
        id: getValue("myUserId"),
        name: getValue("myUserName")
      },
      type: "message",
      text,
      value: {postback: {title}}
    }).subscribe(function(id) {
    }, function(error) {
      return console.log("Error posting activity", error);
    });
  }
}
export function checkListConfirm(checkListId) {
  if (getValue("GDPRObject").isGDPRUserConsentScreenEnabled && !getValue("GDPRObject").privacyPolicyAccepted) {
    showPrivacyPolicyDialog("first");
    const lastObject = getValue("lastObject");
    lastObject.type = "checklist";
    lastObject.value = checkListId;
    setValue("lastObject", lastObject);
    return;
  }
  let selected = [];
  let isOneItemChecked = false;
  getValue("checkListMap").forEach(function(ids) {
    selected.push({
      title: ids.data.title,
      value: ids.data.value,
      isChecked: document.getElementById("checkbox-" + ids.id).checked
    });
    if (document.getElementById("checkbox-" + ids.id).checked) {
      isOneItemChecked = true;
    }
  });
  if (isOneItemChecked) {
    let temp = '<div class="user-message--container"><div class="btn-message user_message">👍</div></div>';
    insertAdjacentHTMLOfChatArea(temp);
    scrollID.scrollTop = scrollID.scrollHeight;
    document.getElementById(checkListId)?.remove();
    const messages = getValue("messages");
    messages.push({
      text: "👍",
      type: "user"
    });
    setValue("messages", messages);
    storeMessages(getValue("botConfig")._id, JSON.stringify(getValue("messages")));
    getValue("directLine").postActivity({
      channel: getValue("selectedChannel"),
      from: {
        id: getValue("myUserId"),
        name: getValue("myUserName")
      },
      type: "message",
      text: "confirm",
      value: {
        postback: {
          type: "checklist",
          checklistTranscriptId: getValue("checkListMap")[0].checklistTranscriptId,
          checklistItems: selected
        }
      }
    }).subscribe(function(id) {
    }, function(error) {
      return console.log("Error posting activity", error);
    });
    changeInputDisable(false);
  } else {
    if (getValue("customToken") === "5f0ed3d497c424067f61c48b") {
      $(".common__error--checklist").html("Selecteer minimaal 1 optie!~");
    } else {
      $(".common__error--checklist").html(ErrorsCodes[getValue("currentValidationLanguage")]["pleaseSelectOneItem"]);
    }
  }
}
export function sliderConfirm(sliderId) {
  if (getValue("GDPRObject").isGDPRUserConsentScreenEnabled && !getValue("GDPRObject").privacyPolicyAccepted) {
    showPrivacyPolicyDialog("first");
    const lastObject = getValue("lastObject");
    lastObject.type = "slider";
    lastObject.value = sliderId;
    setValue("lastObject", lastObject);
    return;
  }
  if (getValue("sliderMap")[sliderId]) {
    let selectedValue = Number(document.getElementById(getValue("sliderMap")[sliderId].sliderId + "-Input").value);
    let value = getValue("sliderMap")[sliderId].data[selectedValue - 1].value;
    disablePreviousMessageType(getValue("currentStepType"));
    let temp = `<div class="user-message--container"><div class="btn-message user_message">${value}</div></div>`;
    insertAdjacentHTMLOfChatArea(temp);
    scrollID.scrollTop = scrollID.scrollHeight;
    const messages = getValue("messages");
    messages.push({
      text: value,
      type: "user"
    });
    setValue("messsages", messages);
    document.getElementById(sliderId).remove();
    getValue("directLine").postActivity({
      channel: getValue("selectedChannel"),
      from: {
        id: getValue("myUserId"),
        name: getValue("myUserName")
      },
      type: "message",
      text: selectedValue,
      value: {
        postback: {
          type: "slider",
          sliderTranscriptId: getValue("sliderMap")[sliderId].transcriptId
        }
      }
    }).subscribe(function(id) {
    }, function(error) {
      return console.log("Error posting activity", error);
    });
    changeInputDisable(false);
  }
}
export function submitForm(formId) {
  if (getValue("GDPRObject").isGDPRUserConsentScreenEnabled && !getValue("GDPRObject").privacyPolicyAccepted) {
    showPrivacyPolicyDialog("first");
    const lastObject = getValue("lastObject");
    lastObject.type = "form";
    lastObject.value = formId;
    setValue("lastObject", lastObject);
    return;
  }
  if (isFormValid(formId)) {
    if (getValue("formMap")[formId]) {
      let myData = {
        type: "form",
        _id: getValue("formMap")[formId].formId
      };
      getValue("formMap")[formId].elements.forEach(function(formElement) {
        if (formElement.data.type === "textInput") {
          if (formElement.data.textInput.validation === "date" || formElement.data.textInput.validation === "dateAndTime") {
            myData[formElement.data.inputMapping] = formElement.value;
          } else {
            let value = $("#" + formElement.id).val().trim();
            myData[formElement.data.inputMapping] = value;
          }
        } else if (formElement.data.type === "checkBox") {
          let rInputs = document.getElementsByName(formElement.id);
          let values = [];
          for (let i = 0; i < rInputs.length; i++) {
            if (rInputs[i].checked) {
              values.push(rInputs[i].value);
            }
          }
          myData[formElement.data.inputMapping] = values.toString();
        } else if (formElement.data.type === "radioButtons") {
          let rInputs = document.getElementsByName(formElement.id);
          let values = "";
          for (let i = 0; i < rInputs.length; i++) {
            if (rInputs[i].checked) {
              values = rInputs[i].value;
            }
          }
          myData[formElement.data.inputMapping] = values;
        } else if (formElement.data.type === "dropdown") {
          let rInputs = document.getElementById(formElement.id);
          let selectedIndex = rInputs.selectedIndex;
          myData[formElement.data.inputMapping] = rInputs.getElementsByTagName("option")[selectedIndex].value;
        }
      });
      document.getElementById(formId).remove();
      var temp = `<div class="user-message--container"><div class="btn-message user_message">👍</div></div>`;
      insertAdjacentHTMLOfChatArea(temp);
      scroll();
      const messages = getValue("messages");
      messages.push({
        text: "👍",
        type: "user"
      });
      setValue("messages", messages);
      storeMessages(getValue("botConfig")._id, JSON.stringify(getValue("messages")));
      changeInputDisable(false);
      getValue("directLine").postActivity({
        channel: getValue("selectedChannel"),
        from: {
          id: getValue("myUserId"),
          name: getValue("myUserName")
        },
        type: "message",
        text: "submit",
        value: {
          postback: myData
        }
      }).subscribe(function(id) {
      }, function(error) {
        return console.log("Error posting activity", error);
      });
    }
  }
}
function isFormValid(formId) {
  let isVaild = true;
  if (getValue("formMap")[formId]) {
    getValue("formMap")[formId].elements.forEach(function(formElement) {
      const errorElement = $("#" + formElement.id + "Error");
      const formHtmlElement = $("#" + formElement.id);
      if (formElement.data.type === "textInput") {
        if (formElement.data.required) {
          if (formElement.data.textInput.validation === "date" || formElement.data.textInput.validation === "dateAndTime") {
            if (!formElement.value) {
              isVaild = false;
              errorElement.text("Please " + formElement.data.textInput.placeholder + "!");
            } else {
              errorElement.text("");
            }
          } else {
            if (formHtmlElement.val().trim() !== "") {
              errorElement.text("");
            } else {
              isVaild = false;
              if (errorElement.text().trim() === "") {
                errorElement.text("Please " + formElement.data.textInput.placeholder + "!");
              }
            }
          }
        }
        switch (formElement.data.textInput.validation) {
          case "number":
            if (/^\d*[1-9]\d*$/.test(formHtmlElement.val())) {
              errorElement.text("");
            } else {
              isVaild = false;
              if (errorElement.text().trim() === "") {
                if (getValue("customToken") === "5f0ed3d497c424067f61c48b") {
                  errorElement.text("Voer een geldig nummer in!");
                } else {
                  errorElement.text(ErrorsCodes[getValue("currentValidationLanguage")]["number"]);
                }
              }
            }
            break;
          case "email":
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formHtmlElement.val())) {
              errorElement.text("");
            } else {
              isVaild = false;
              if (errorElement.text().trim() === "") {
                if (getValue("customToken") === "5f0ed3d497c424067f61c48b") {
                  errorElement.text("Voer een geldig e-mail adres in!");
                } else {
                  errorElement.text(ErrorsCodes[getValue("currentValidationLanguage")]["email"]);
                }
              }
            }
            break;
          case "phoneNumber":
            if (/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/.test(formHtmlElement.val())) {
              errorElement.text("");
            } else {
              isVaild = false;
              if (errorElement.text().trim() === "") {
                if (getValue("customToken") === "5f0ed3d497c424067f61c48b") {
                  errorElement.text("Voer een geldig telefoonnummer in!");
                } else {
                  errorElement.text(ErrorsCodes[getValue("currentValidationLanguage")]["contactNumber"]);
                }
              }
            }
            break;
        }
      } else if (formElement.data.type === "radioButtons" || formElement.data.type === "checkBox") {
        if (formElement.data.required) {
          let rInputs = document.getElementsByName(formElement.id);
          errorElement.text("");
          let isCheckedOne = false;
          for (let i = 0; i < rInputs.length; i++) {
            if (rInputs[i].checked) {
              isCheckedOne = true;
            }
          }
          if (!isCheckedOne) {
            isVaild = false;
            errorElement.text("Please select " + formElement.data.label + "!");
          }
        }
      } else if (formElement.data.type === "dropdown") {
        if (formElement.data.required) {
          let rInputs = document.getElementById(formElement.id);
          errorElement.text("");
          if (!rInputs.value) {
            isVaild = false;
            errorElement.text("Please select " + formElement.data.label + "!");
          }
        }
      }
    });
  }
  return isVaild;
}
export function onDate(i) {
  if (getValue("GDPRObject").isGDPRUserConsentScreenEnabled && !getValue("GDPRObject").privacyPolicyAccepted) {
    showPrivacyPolicyDialog("first");
    const lastObject = getValue("lastObject");
    lastObject.type = "date";
    lastObject.value = i;
    setValue("lastObject", lastObject);
    return;
  }
  let b = "date_btn" + i;
  let date = document.getElementById(b).value;
  let a = document.getElementById(b);
  addDateText(date);
  var nDate = new Date(date);
  if (getValue("selectedChannel") === "universal") {
    sendUniversalActivity({type: "date", date: nDate});
  } else {
    getValue("directLine").postActivity({
      channel: getValue("selectedChannel"),
      from: {
        id: getValue("myUserId"),
        name: getValue("myUserName")
      },
      type: "message",
      text: nDate.toISOString(),
      value: {
        postback: {
          type: "date"
        }
      }
    }).subscribe(function(id) {
    }, function(error) {
      return console.log("Error posting activity", error);
    });
  }
  $(".datepicker").css("display", "none");
  $("#date_btn" + i).css("display", "none");
  changeInputDisable(false);
}
function addDateText(date) {
  let temp = `<div class="user-message--container"><div class="user_message primary-bgcolor">${date}</div></div>`;
  insertAdjacentHTMLOfChatArea(temp);
  const messages = getValue("messages");
  messages.push({
    text: date,
    type: "user"
  });
  setValue("messages", messages);
  scrollID.scrollTop = scrollID.scrollHeight;
}
export const sendDirectLineEvent = (eventObject, isNewUserAsRestart = false) => {
  getValue("directLine").postActivity(eventObject).subscribe(function(data) {
    disablePreviousMessageType(getValue("currentStepType"));
    if (isNewUserAsRestart && window.isPreview) {
      parent.postMessage({
        type: ParentPostMessageType.NEWUSER,
        value: getValue("myUserId")
      }, getValue("currentDomain"));
    }
  }, function(error) {
    return console.log("Error posting activity", error);
  });
};
export const interactiveListButtonClick = () => {
  let tmpSections = ``;
  getValue("interactiveListSection").forEach((section, index) => {
    let tmpRows = ``;
    let tmpId = Date.now() + "-" + index;
    section.rows.forEach((row) => {
      tmpRows += `<div class="interactive-list-item" id="${row.id}"><div>${row.title}<div class="interactive-list-desc">${row.description}</div></div><div class="interactive-list-radio-outer"><div class="interactive-list-radio-inner"></div></div></div>`;
      const buttonMap = getValue("buttonMap");
      buttonMap[tmpId] = {
        title: row.title,
        value: row.id
      };
      setValue("buttonMap", buttonMap);
    });
    tmpSections += `<span class="interactive-section-title">${section.title}</span><div class="interactive-row-container">${tmpRows}</div>`;
  });
  let tmpInteractiveMenu = `<div class="interactive-list-action">
            <div class="interactive-list-action-header"><i class="material-icons" id="interactive-menu-close">close</i><span>Menu</span></div>
            ${tmpSections}
            <div class="interactive-list-action-save-btn">Save</div>
        </div>`;
  insertAdjacentHTMLOfChatArea(tmpInteractiveMenu);
  $("#interactive-menu-close").on("click", () => {
    $(".interactive-list-action").remove();
  });
  let selectedRow = {
    title: "",
    value: ""
  };
  $(".interactive-list-item").on("click", (event) => {
    selectedRow = {
      title: event.delegateTarget.innerText.split("\n")[0],
      value: event.delegateTarget.id
    };
    const interactivceRowList = [...$(".interactive-list-item")];
    interactivceRowList.forEach((rowHtmlElement) => {
      document.getElementById(rowHtmlElement.id).classList.remove("selected-row");
    });
    document.getElementById(event.delegateTarget.id).classList.add("selected-row");
  });
  $(".interactive-list-action-save-btn").on("click", () => {
    let tempButton = `<div class="user-message--container"> <div class="btn-message user_message"> ${selectedRow.title}</div></div>`;
    insertAdjacentHTMLOfChatArea(tempButton);
    const messages = getValue("messages");
    messages.push({
      text: selectedRow.title,
      type: "user"
    });
    setValue("messages", messages);
    getValue("directLine").postActivity({
      channel: getValue("selectedChannel"),
      from: {
        id: getValue("myUserId"),
        name: getValue("myUserName")
      },
      type: "message",
      text: selectedRow.value,
      value: {postback: selectedRow}
    }).subscribe(function(id) {
    }, function(error) {
      return console.log("Error posting activity", error);
    });
    $(".interactive-list-action").remove();
  });
};
export function scroll() {
  scrollID.scrollTop = scrollID.scrollHeight;
}
export const addJqueryEventListners = () => {
  $("body").on("click", ".buttons", (event) => {
    if (event && event.currentTarget && event.currentTarget.id) {
      buttonClick(event.currentTarget.id);
      if ($(`#${event.currentTarget.id}`).hasClass("card-btn")) {
        event.stopPropagation();
        event.preventDefault();
        event.stopImmediatePropagation();
        ;
      }
    }
  });
  $("body").on("click", ".date_button", (event) => {
    if (event && event.target && event.target.id) {
      onDate(event?.target?.id.split("date_btn")[1]);
    }
  });
  $("body").on("click", ".slider__submit__button", (event) => {
    if (event && event.target && event.target.id) {
      sliderConfirm(event?.target?.id);
    }
  });
  $("body").on("click", ".checklist__option__button", (event) => {
    if (event && event.target && event.target.id) {
      checkListConfirm(event.target.id);
    }
  });
  $("body").on("click", ".form_submit", (event) => {
    if (event && event.target && event.target.id) {
      submitForm(event.target.id);
    }
  });
  $("body").on("click", ".dialogButton", (event) => {
    if (event && event.target && event.target.id !== "policy") {
      showPrivacyPolicyDialog(event.target.id);
    }
  });
  $("body").on("click", ".interactive-list", (event) => {
    interactiveListButtonClick();
  });
};
export const insertAdjacentHTMLOfChatArea = (html) => {
  scrollID.insertAdjacentHTML("beforeend", html);
};
export function toggleMenu(flag) {
  let menuIcon = document.getElementById("menu-icon");
  if (menuIcon) {
    menuIcon.style.display = !flag ? "none" : "block";
  }
}
