export const commonVarObject = {
  baseUrl: "",
  currentPreviewMode: false,
  currentValidationLanguage: "en",
  currentDomain: void 0,
  selectedChannel: "webchat",
  buttonMap: {},
  checkListMap: [],
  formMap: {},
  interactiveListSection: [],
  currentStepType: "",
  GDPRObject: {},
  dialogHtml: "",
  dialogHtml1: "",
  dialogHtml2: "",
  lastObject: {},
  messages: [],
  directLine: null,
  myUserId: `myUserId${Date.now()}`,
  myUserName: `myUserName${Date.now()}`,
  sliderMap: {},
  botConfig: {},
  isMobile: false,
  audioNumber: 1,
  percentage: 0,
  currentConversationID: "",
  universalAccessToken: "",
  isFileUploadInProgress: false,
  customToken: null,
  universalChannelCred: null
};
export let scrollID;
export const getValue = (key) => {
  if (commonVarObject) {
    return commonVarObject[key];
  }
};
export const setValue = (key, value) => {
  if (commonVarObject) {
    commonVarObject[key] = value;
  }
};
export function setScrollId() {
  scrollID = document.getElementById("scrollId");
}
