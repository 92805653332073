import {initializeApp} from "../_snowpack/pkg/firebase/app.js";
import {getMessaging, getToken} from "../_snowpack/pkg/firebase/messaging.js";
import {CODEBASE_URL} from "../consts/common.const.js";
export function initFirebaseApp(botConfig) {
  const iframeWin = document.getElementById("myFrame").contentWindow;
  var firebaseConfig = {
    apiKey: botConfig.firebaseConfig.apiKey,
    authDomain: botConfig.firebaseConfig.authDomain,
    databaseURL: botConfig.firebaseConfig.databaseURL,
    projectId: botConfig.firebaseConfig.projectId,
    messagingSenderId: botConfig.firebaseConfig.messagingSenderId,
    appId: botConfig.firebaseConfig.appId
  };
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  navigator.serviceWorker.addEventListener("message", (message) => {
    let messageRecieve;
    if (message.data.hasOwnProperty("firebaseMessagingData")) {
      messageRecieve = JSON.parse(message.data.firebaseMessagingData.data.payload);
    } else {
      messageRecieve = message.data;
    }
    if (!window.isPreview) {
      var event = new CustomEvent("StartFlow", {
        detail: {flowId: messageRecieve.data.flowId, eventType: "proactive-message"}
      });
      window.dispatchEvent(event);
    }
  });
  getToken(messaging, {vapidKey: botConfig.firebaseConfig.usePublicVapidKey}).then((currentToken) => {
    if (currentToken) {
      iframeWin?.postMessage(JSON.stringify({type: "fcmToken", data: {fcmToken: currentToken}}), CODEBASE_URL + "index.html");
      localStorage.setItem(botConfig.chatBotDesign._id + "-fcm-token", JSON.stringify(currentToken));
    } else {
      console.log("No registration token available. Request permission to generate one.");
    }
  }).catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
  });
}
